import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
`;

export const Text = styled(Typography)`
  color: #53464f;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
