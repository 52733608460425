import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95%;
  background: transparent;
`;

export const AvatarContainer = styled.div`
  background: #fff;
  width: 56px;
  height: 56px;
  border-radius: 100px;
  z-index: 99;
`;

export const Avatar = styled.div<{ hasImage: boolean }>`
  width: 56px;
  min-width: 56px;
  width: 56px;
  min-height: 56px;
  height: 56px;
  background: ${({ hasImage }) => (hasImage ? "#FFFFFF" : "#FFE0EF")};
  border: ${({ hasImage }) =>
    hasImage ? "1px solid #FFFFFF" : "3.75px solid #FFFFFF"};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100px;
`;

export const AvatarName = styled(Typography)`
  color: #f20d7a;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 0px 24px 24px;
  border-top: 2px solid #fe2b8f;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: center;
  min-height: 133px;
`;

export const Elipsis = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 4px;
`;

export const PersonName = styled(Typography)`
  text-align: center;
  font-weight: 700 !important;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: #333333;
`;

export const RoleName = styled(Typography)`
  text-align: center;
  font-weight: 700 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #83727d;
`;

export const ThreeDotsContainer = styled.div<{
  permission: boolean | undefined;
}>`
  width: 100%;
  height: 5%;
  opacity: 0;
  justify-content: flex-end;
  display: flex;
  margin-right: -30px;
  margin-top: 15px;
  margin-bottom: 5px;
  visibility: ${({ permission }) => (permission ? "visible" : "hidden")};

  ${CardContainer}:hover & {
    opacity: 1;
  }
`;

export const CardTopDiv = styled.div`
  display: flex;
  background: #fff;
  width: inherit;
  flex-direction: column;
  height: 100%;
`;

export const AvatarTopDiv = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  min-height: 28px;
`;

export const AddUnderIcon = styled.button<{
  permission: boolean | undefined;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: ${({ permission }) => (permission ? "visible" : "hidden")};
  min-width: 40px;
  min-height: 40px;
  background: #f8f6f8;
  border-radius: 50%;
  position: relative;
  top: 24px;

  ${CardContainer}:hover & {
    opacity: 1;
  }
`;

export const CompanyLogo = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 168px;
  object-fit: cover;
  height: 80%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 45%;
  position: relative;
  top: -32px;
  border: 1px solid #ebe6e9;
  border-radius: 12px;
`;

export const IconNumber = styled(Typography)`
  font-weight: 600 !important;
  color: #b9acb4;
  margin-left: 4px;
  margin-top: 2px;
`;
