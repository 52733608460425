import {
  Button,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import styled from "styled-components";
import { ToolbarButton } from "../Toolbar/components/ToolbarButton";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  padding: 40px 24px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Company = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin-top: 6px;

  color: ${({ theme }) => theme.colors.neutral[40]};
`;

const BackboneButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

interface ITitle {
  count: number | undefined;
  hasViewedOrgChart: boolean | undefined;
  hasPermission: boolean;
}

export const Title = ({ count, hasViewedOrgChart, hasPermission }: ITitle) => {
  const { selectedCompany } = useSelectedCompany();
  const navigate = useNavigate();
  const name = selectedCompany?.name;

  const options = [
    // {
    //   key: "edit-platform",
    //   onClick: () => {},
    //   children: (
    //     <Option>
    //       <Icons name="IconClick" fill="transparent" />
    //       Editar via plataforma
    //     </Option>
    //   ),
    // },
    {
      key: "edit-sheet",
      onClick: () => navigate("/employees/import-sheet/update/upload-file"),
      children: (
        <Option>
          <Icons name="IconTable" fill="transparent" />
          Editar via planilha
        </Option>
      ),
    },
  ];

  return (
    <Container>
      <div>
        <Typography variant="headline6">Organograma</Typography>
        <Company>
          <Icons name="IconBuilding" fill="transparent" size={14} />
          <Typography variant="body4">{name}</Typography>
          <Typography variant="body4">•</Typography>
          <Icons name="IconUsers" fill="transparent" size={14} />
          <Typography variant="body4">
            {count} {count === 1 ? "pessoa" : "pessoas"}
          </Typography>
        </Company>
      </div>
      {hasViewedOrgChart && (
        <BackboneButtons>
          {hasPermission && (
            <>
              <ToolbarButton
                icon="IconUsers"
                text="Equipe"
                handleClick={() => navigate("/employees")}
                segmentEvent="people_strategic_hr_orgchart_redirect_to_backbone_button_clicked"
                disabled={false}
                size="medium"
              />

              <Menu
                type={"default"}
                options={options}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <Button variant="primary" size="medium">
                  Editar organograma
                  <Icons name="IconEdit" fill="transparent" />
                </Button>
              </Menu>
            </>
          )}
        </BackboneButtons>
      )}
    </Container>
  );
};
