import { Divider, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { Orgchart } from "@components/Orgchart";
import { useEffect, useRef, useState } from "react";
import { OrgChart } from "src/lib";
import { trpc } from "@api/client";
import {
  getFromLS,
  setInLS,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Title } from "./components/Title";
import { Toolbar } from "./components/Toolbar";
import styled from "styled-components";
import { TOrgchartQueryProps } from "./components/Toolbar/components/ModalExport";
import dispatchToast from "src/utils/dispatchToast";
import { segment } from "src/utils/segment";
import { GetOrgChartByLeaderResponseV2 } from "server/src/services";
import { EmptyState } from "./components/EmptyState";

const Container = styled.div`
  max-height: calc(100vh - 74px);
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const PageMain = () => {
  const [hasViewedOrgChart, setHasViewedOrgChart] = useState(true);
  const { selectedCompany } = useSelectedCompany();
  const currentCompanyId = selectedCompany?.id;

  const permissions = usePermissions();
  const hasPermission = permissions?.companies?.some(
    (company) =>
      company?.permissions?.includes("people_orgchart_update") ||
      company?.permissions?.includes("*") ||
      permissions?.isAdmin
  );

  const hasBackbonePermission = permissions?.companies.some(
    (company) =>
      company?.permissions?.includes("core_manage_employees_list") ||
      company?.permissions?.includes("*") ||
      permissions?.isAdmin
  );

  segment({ track: `people_strategic_hr_orgchart_view` });

  const [orgchartQueryProps, setOrgchartQueryProps] =
    useState<TOrgchartQueryProps>();

  const [orgchartData, setOrgchartData] =
    useState<GetOrgChartByLeaderResponseV2>();

  const orgchartRef = useRef<OrgChart<unknown>>(new OrgChart());

  const [
    {
      data: getOrgChartByLeader,
      refetch: getOrgChartByLeaderRefetch,
      isLoading: getOrgChartByLeaderIsLoading,
    },
    // {
    //   data: getRolesAndDepartmentsResponse,
    //   refetch: getRolesAndDepartmentsResponseRefetch,
    //   isLoading: getRolesAndDepartmentsResponseIsLoading,
    // },
  ] = trpc.useQueries((t) => [
    t.getOrgchartByLeaderV2(
      {
        leaderId: orgchartQueryProps?.leaderId ?? "",
        levelsToExport: orgchartQueryProps?.levelsToExport ?? 0,
        companyId: currentCompanyId,
        level: orgchartQueryProps?.level ?? 2,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setHasViewedOrgChart(data?.hasViewedOrgChart);
          if (
            orgchartQueryProps?.level == 2 ||
            orgchartQueryProps?.level == undefined
          ) {
            setInLS({
              key: "orgchart",
              value: { levels: 0, orgchart: [], total: 0 },
            });

            setInLS({ key: "orgchart", value: data });

            setOrgchartData(data);
          } else {
            const existingData = getFromLS("orgchart") || {};
            const newOrgchartData = [
              ...existingData?.orgchart,
              ...data.orgchart,
            ];

            const cleaned = newOrgchartData.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id == item.id)
            );

            setInLS({
              key: "orgchart",
              value: { levels: existingData?.levels, orgchart: [] },
            });

            setInLS({
              key: "orgchart",
              value: {
                levels: existingData?.levels,
                orgchart: cleaned,
                total: existingData?.total,
              },
            });
          }

          const storedData = getFromLS("orgchart");
          setOrgchartData(storedData);
        },
        onError: () => {
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao carregar o Organograma. Por favor, recarregue a página ou tente novamente mais tarde.",
          });
        },
      }
    ),
    // t.getRolesAndDepartments(
    //   { companyId: currentCompanyId },
    //   {
    //     retry: false,
    //     refetchOnWindowFocus: false,
    //   }
    // ),
  ]);

  const getDirectSubordinates = trpc.getDirectSubordinates.useMutation({
    onSuccess: (data) => {
      data?.orgchart?.forEach((org) => orgchartData?.orgchart?.push(org));
      let cleaned = Array.from(new Set(orgchartData?.orgchart));

      setInLS({
        key: "orgchart",
        value: { levels: 0, orgchart: [], total: 0 },
      });

      setInLS({
        key: "orgchart",
        value: {
          levels: orgchartData?.levels,
          orgchart: cleaned,
          total: orgchartData?.total,
        },
      });
    },
    onError: () => ({}),
  });

  const setViewOrgchart = trpc.setViewOrgchart.useMutation({
    onSuccess: () => {
      window?.location?.reload();
    },
    onError: () => ({}),
  });

  const refetch = () => {
    getOrgChartByLeaderRefetch();
    // getRolesAndDepartmentsResponseRefetch();
  };

  const handleDirectSubordinates = (employeeId: string) => {
    getDirectSubordinates.mutate({
      companyId: currentCompanyId,
      employeeId,
    });
  };

  const handleRolesAndDepartmentFromOrgchart = (
    orgchartData: GetOrgChartByLeaderResponseV2 | undefined
  ) => {
    let roles: { label: string; value: string }[] = [];
    let departments: { label: string; value: string }[] = [];

    if (orgchartData?.orgchart) {
      for (const node of orgchartData?.orgchart) {
        if (node?.roles) {
          const role = { label: node?.roles?.name, value: node?.roles?.id };
          roles?.push(role);
        }

        if (node?.departments) {
          const department = {
            label: node?.departments?.name,
            value: node?.departments?.id,
          };
          departments?.push(department);
        }
      }
    }

    return { roles, departments };
  };

  return (
    <Container>
      <PageContainer>
        <Wrapper>
          <Title
            count={orgchartData?.total}
            hasViewedOrgChart={hasViewedOrgChart}
            hasPermission={hasBackbonePermission}
          />
          {hasViewedOrgChart && (
            <Toolbar
              orgchartRef={orgchartRef}
              getOrgChartByLeader={orgchartData}
              getRolesAndDepartments={handleRolesAndDepartmentFromOrgchart(
                orgchartData
              )}
              setOrgchartQueryProps={setOrgchartQueryProps}
              isLoading={
                // getRolesAndDepartmentsResponseIsLoading ||
                getDirectSubordinates?.isLoading || getOrgChartByLeaderIsLoading
              }
              refetchOrgchart={getOrgChartByLeaderRefetch}
            />
          )}
        </Wrapper>
      </PageContainer>
      <Divider orientation="horizontal" />

      {!hasViewedOrgChart ? (
        <EmptyState
          loading={getOrgChartByLeaderIsLoading || setViewOrgchart?.isLoading}
          hasPermission={hasPermission}
          hasBackbonePermission={hasBackbonePermission}
          viewOrgchart={() =>
            setViewOrgchart.mutate({
              companyId: currentCompanyId,
            })
          }
        />
      ) : (
        <Orgchart
          ref={orgchartRef}
          getOrgChartByLeader={orgchartData}
          getDirectSubordinates={handleDirectSubordinates}
          isLoading={
            getOrgChartByLeaderIsLoading ||
            getDirectSubordinates?.isLoading ||
            setViewOrgchart?.isLoading
          }
          refetch={refetch}
          interactive={!getOrgChartByLeaderIsLoading}
          compactOrgchart={false}
          hasPermission={hasPermission}
        />
      )}
    </Container>
  );
};
