import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  AddIcon,
  AddUnderIcon,
  ButtonContainer,
  ExpandButtonContainer,
  IconNumber,
} from "./styled";

interface IExpandButton {
  directSubordinates: number;
  totalSubordinates: number;
  children: any[];
  node: any;
}

export const Expand = ({
  directSubordinates,
  totalSubordinates,
  children,
  node,
}: IExpandButton) => {
  return (
    <ButtonContainer>
      {node && (
        <ExpandButtonContainer>
          <Icons
            name={"IconUser"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"18px"}
          />
          <IconNumber variant="body4">{directSubordinates}</IconNumber>
          <Icons
            name={"IconUsers"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"18px"}
            style={{ marginLeft: "14px" }}
          />

          <IconNumber variant="body4">{totalSubordinates}</IconNumber>

          <Icons
            name={children ? "IconChevronUp" : "IconChevronDown"}
            fill="#FFFFFF"
            color="#B9ACB4"
            width={"16px"}
            style={{ marginLeft: "6px" }}
          />
        </ExpandButtonContainer>
      )}
    </ButtonContainer>
  );
};
