import { ToolbarButton } from "./components/ToolbarButton";
import { Search } from "./components/Search";
import { FilterButton } from "./components/FilterButton";
import styled from "styled-components";
import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { OrgChart } from "src/lib";
import { ModalExport, TOrgchartQueryProps } from "./components/ModalExport";
import {
  GetOrgChartByLeaderResponseV2,
  GetRolesAndDepartmentsResponse,
} from "server/src/services";
import { TFilters } from "./components/FilterButton/FilterDrawer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 16px;
`;

const searchInclude = (search: string[], value: string) => {
  if (!value) return false;
  return !!search?.some((s) => {
    return s
      ?.toLowerCase()
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.includes(
        value
          ?.toLowerCase()
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
      );
  });
};

export interface IToolbar {
  orgchartRef: MutableRefObject<OrgChart<unknown>>;
  getOrgChartByLeader?: GetOrgChartByLeaderResponseV2;
  getRolesAndDepartments?: {
    roles: { label: string; value: string }[];
    departments: { label: string; value: string }[];
  };
  setOrgchartQueryProps: Dispatch<SetStateAction<TOrgchartQueryProps>>;
  isLoading: boolean;
  refetchOrgchart: () => void;
}

export const Toolbar = ({
  orgchartRef,
  getOrgChartByLeader,
  getRolesAndDepartments,
  setOrgchartQueryProps,
  isLoading,
  refetchOrgchart,
}: IToolbar) => {
  const [expand, setExpand] = useState<boolean>(true);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [modalKey, setModalKey] = useState<number>(0);
  const [loadLevel, setLoadLevel] = useState<number>(2);

  const searchFilter = (e, name) => {
    const value = e?.target?.value ?? name;
    orgchartRef.current.clearHighlighting();

    const data = orgchartRef.current.data();

    data?.forEach((d: any) => {
      if (searchInclude([d.name, d.roles?.name, d.departments?.name], value)) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    const focusedNodeId = (data as any)?.find((node: any) => {
      return searchInclude(
        [node.name, node.roles?.name, node.departments?.name],
        value
      );
    })?.id;

    orgchartRef.current.data(data).render().setCentered(focusedNodeId);
  };

  const handleFilter = (filters: TFilters | undefined) => {
    if (!orgchartRef?.current?.data()) return;
    orgchartRef?.current?.clearHighlighting();

    const data = orgchartRef?.current?.data();

    data?.forEach((d: any) => {
      if (filters?.filteredEmployeeIds?.includes(d.id)) {
        d._highlighted = true;
        d._expanded = true;
      }
    });

    orgchartRef?.current?.data(data).render().fit();
  };

  const handleCloseModal = () => {
    setOpenExportModal(false);
    setOrgchartQueryProps({ leaderId: "", levelsToExport: 0 });
    orgchartRef?.current?.expandAll()?.fit();
    setModalKey((prev) => prev + 1);
  };

  const loadMore = () => {
    const currentLevel = loadLevel + 1;
    setLoadLevel(currentLevel);

    setOrgchartQueryProps({
      leaderId: "",
      levelsToExport: 0,
      level: currentLevel,
    });

    refetchOrgchart();
  };

  return (
    <>
      <Container>
        <Search
          label="Buscar por pessoa, cargo ou departamento"
          onChange={searchFilter}
          onInputChange={searchFilter}
          getRolesAndDepartmentsResponse={getRolesAndDepartments}
          getOrgChartV2Response={getOrgChartByLeader?.orgchart}
          disabled={isLoading}
        />
        <Wrapper>
          <FilterButton
            getRolesAndDepartmentsResponse={getRolesAndDepartments}
            getOrgChartResponse={getOrgChartByLeader}
            onFilter={handleFilter}
            disabled={isLoading}
          />
          <ToolbarButton
            disabled={isLoading}
            text={expand ? "Colapsar" : "Expandir"}
            handleClick={() => {
              if (expand) {
                orgchartRef?.current?.collapseAll()?.fit({ scale: false });
              } else {
                orgchartRef?.current?.expandAll()?.fit();
              }
              setExpand(!expand);
            }}
            icon={expand ? "IconArrowsDiagonalMinimize" : "IconArrowsDiagonal"}
            segmentEvent="people_strategic_hr_orgchart_expandorgchart_button_clicked"
          />
          {getOrgChartByLeader?.orgchart && (
            <ToolbarButton
              text="Expandir próximo nível"
              handleClick={loadMore}
              icon="IconArrowsMoveVertical"
              segmentEvent="people_strategic_hr_orgchart_loadmorergchart_button_clicked"
              disabled={isLoading}
            />
          )}
          <ToolbarButton
            disabled={isLoading}
            text="Exportar organograma"
            handleClick={() => setOpenExportModal(true)}
            icon="IconFileExport"
            segmentEvent="people_strategic_hr_orgchart_exportorgchart_button_clicked"
          />
        </Wrapper>
      </Container>
      <ModalExport
        key={modalKey}
        isOpen={openExportModal}
        closeModal={handleCloseModal}
        getOrgChartByLeader={getOrgChartByLeader}
        onQueryPropsChange={(queryProps) => setOrgchartQueryProps(queryProps)}
        isLoading={isLoading}
        refetch={refetchOrgchart}
      />
    </>
  );
};
