import { ButtonsContainer, Container, Text, Option } from "./styled";
import Empty from "../../../../assets/empty.png";
import { Button, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { useNavigate } from "react-router-dom";

export const EmptyState = ({
  viewOrgchart,
  loading,
  hasPermission,
  hasBackbonePermission,
}: {
  viewOrgchart: () => void;
  loading: boolean;
  hasPermission: boolean;
  hasBackbonePermission: boolean;
}) => {
  const navigate = useNavigate();

  const options = [
    // {
    //   key: "edit-platform",
    //   onClick: () => {},
    //   children: (
    //     <Option>
    //       <Icons name="IconClick" fill="transparent" />
    //       Editar via plataforma
    //     </Option>
    //   ),
    // },
    {
      key: "edit-sheet",
      onClick: () => navigate("/employees/import-sheet/update/upload-file"),
      children: (
        <Option>
          <Icons name="IconTable" fill="transparent" />
          Editar via planilha
        </Option>
      ),
    },
  ];
  return (
    <div
      style={{
        background: "#f8f6f8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Container>
        <img src={Empty} alt="Organograma vazio" />
        <Text variant="body3" weight={600} color="#53464F">
          O organograma será exibido aqui
        </Text>
        <Text variant="body4" color="#53464F">
          Para visualizar as pessoas, é necessário que elas sejam <br />
          adicionadas à equipe e tenham um líder associado no cadastro <br />
          completo. Verifique essas informações antes de mostrar o <br />
          organograma.
        </Text>
        {hasPermission && (
          <ButtonsContainer>
            <Button
              variant="primary"
              size="large"
              onClick={viewOrgchart}
              loading={loading}
            >
              Mostrar organograma
            </Button>

            {hasBackbonePermission && (
              <Menu
                type={"default"}
                options={options}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <Button variant="primary" size="large" loading={loading}>
                  Editar organograma
                </Button>
              </Menu>
            )}
          </ButtonsContainer>
        )}
      </Container>
    </div>
  );
};
