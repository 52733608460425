import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  AddUnderIcon,
  Avatar,
  AvatarContainer,
  AvatarImage,
  AvatarName,
  AvatarTopDiv,
  ButtonContainer,
  CardContainer,
  CardTopDiv,
  CompanyLogo,
  Elipsis,
  IconNumber,
  InfosContainer,
  PersonName,
  RoleName,
  ThreeDotsContainer,
} from "./styled";
import { formatRegistrationNumber } from "src/utils/formatRegistrationNumber";

type ICard = {
  image: string;
  name: string;
  role: string;
  nameInitials: string;
  onClick: (e) => void;
  isLoading?: boolean;
  interactive: boolean;
  registrationNumber: string;
  hasPermission?: boolean;
  directReports?: number | undefined;
  totalReports?: number | undefined;
  showButton?: boolean | undefined;
};

export const Card = ({
  image,
  name,
  role,
  nameInitials,
  onClick,
  isLoading,
  interactive,
  registrationNumber,
  hasPermission,
  directReports,
  totalReports,
  showButton,
}: ICard) => {
  return (
    <CardContainer>
      <CardTopDiv>
        {!registrationNumber ? (
          <AvatarTopDiv>
            <AvatarContainer>
              <Avatar hasImage={!!image}>
                {image ? (
                  <AvatarImage src={image} />
                ) : (
                  <AvatarName weight={700} variant="body1">
                    {nameInitials}
                  </AvatarName>
                )}
              </Avatar>
            </AvatarContainer>
          </AvatarTopDiv>
        ) : (
          <div style={{ height: "84px" }} />
        )}

        <InfosContainer
          style={{
            padding: registrationNumber ? "38px" : "0px 24px 24px",
            gap: registrationNumber ? "8px" : "4px",
          }}
        >
          {interactive && !registrationNumber && (
            <ThreeDotsContainer permission={hasPermission}>
              <Icons
                className="card-button-foreign-object"
                name="IconDotsVertical"
                width={"16px"}
                color="#53464F"
                onClick={(e) => {
                  onClick(e);
                }}
              />
            </ThreeDotsContainer>
          )}
          {/* {isLoading ? (
            <Skeleton style={{ marginTop: "32px", width: "100%" }} />
          ) : registrationNumber && image ? (
            <CompanyLogo alt="company-logo" src={image} />
          ) : (
            <PersonName variant="body3">{name}</PersonName>
          )} */}
          {registrationNumber && image ? (
            <CompanyLogo alt="company-logo" src={image} />
          ) : (
            <PersonName variant="body3">{name}</PersonName>
          )}

          {/* {isLoading ? (
            <Skeleton style={{ marginTop: "8px", width: "100%" }} />
          ) : (
            <Elipsis style={{ minHeight: "20px" }}>
              <RoleName variant="body4">
                {registrationNumber
                  ? `CNPJ ${formatRegistrationNumber(registrationNumber)} `
                  : role}
              </RoleName>
            </Elipsis>
          )} */}
          <Elipsis style={{ minHeight: "20px" }}>
            <RoleName variant="body4">
              {registrationNumber
                ? `CNPJ ${formatRegistrationNumber(registrationNumber)} `
                : role}
            </RoleName>
          </Elipsis>
        </InfosContainer>

        {directReports != 0 && totalReports != 0 && showButton ? (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="expand-btn"
          >
            <ButtonContainer
              className="expand-btn"
              style={{ pointerEvents: isLoading ? "none" : "auto" }}
            >
              <Icons
                name={"IconUser"}
                fill="#FFFFFF"
                color="#B9ACB4"
                width={"18px"}
                className="expand-btn"
              />
              <IconNumber variant="body4" className="expand-btn">
                {directReports}
              </IconNumber>
              <Icons
                className="expand-btn"
                name={"IconUsers"}
                fill="#FFFFFF"
                color="#B9ACB4"
                width={"18px"}
                style={{ marginLeft: "14px" }}
              />
              <IconNumber variant="body4" className="expand-btn">
                {totalReports}
              </IconNumber>
              <Icons
                className="expand-btn"
                name={"IconChevronDown"}
                fill="#FFFFFF"
                color="#B9ACB4"
                width={"16px"}
                style={{ marginLeft: "6px" }}
              />
            </ButtonContainer>
          </div>
        ) : (
          <></>
        )}
      </CardTopDiv>
      {!isLoading && (
        <AddUnderIcon permission={hasPermission} className="card-button-add">
          <Icons
            name="Add"
            width={"12px"}
            color="#53464F"
            className="card-button-add"
          />
        </AddUnderIcon>
      )}
    </CardContainer>
  );
};
