import { TagNumber } from "@components/TagNumber";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import {
  GetOrgChartByLeaderResponseV2,
  GetRolesAndDepartmentsResponse,
} from "server/src/services";
import { segment } from "src/utils/segment";
import { FilterDrawer, TFilters } from "./FilterDrawer";

interface IFilterButton {
  getRolesAndDepartmentsResponse?: GetRolesAndDepartmentsResponse;
  getOrgChartResponse: GetOrgChartByLeaderResponseV2 | undefined;
  onFilter: (filters: TFilters | undefined) => void;
  disabled: boolean;
}

export const FilterButton = ({
  getRolesAndDepartmentsResponse,
  getOrgChartResponse,
  onFilter,
  disabled,
}: IFilterButton) => {
  const [filteredNodeIds, setFilteredNodeIds] = useState<string[]>([]);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    TFilters | undefined
  >();

  const handleSearch = async (filteredEmployees: string[]) => {
    if (filteredEmployees.length) {
      if (filteredEmployees.length == 1) {
        setFilteredNodeIds(filteredEmployees);
      } else {
      }
    } else {
      setFilteredNodeIds([]);
    }
  };

  useEffect(() => {
    onFilter && onFilter(selectedFilterOptions);
  }, [selectedFilterOptions]);

  const activeFilterLength =
    selectedFilterOptions?.filteredEmployeeIds?.length ?? 0;

  return (
    <>
      <Button
        variant="tertiary"
        size="small"
        style={{
          background:
            filteredNodeIds.length && activeFilterLength > 0
              ? "#FFCCE4"
              : (!getRolesAndDepartmentsResponse?.roles?.length &&
                  !getRolesAndDepartmentsResponse?.departments?.length) ||
                disabled
              ? "#EBE6E9"
              : "#fff",
        }}
        onClick={() => {
          handleSearch([]);
          setOpenFilters(true);
          segment({
            track: `people_strategic_hr_orgchart_filter_button_clicked`,
          });
        }}
        disabled={
          (!getRolesAndDepartmentsResponse?.roles?.length &&
            !getRolesAndDepartmentsResponse?.departments?.length) ||
          disabled
        }
      >
        <Typography
          variant="caption"
          weight={700}
          style={{
            color:
              filteredNodeIds.length && activeFilterLength > 0
                ? "#F20D7A"
                : (!getRolesAndDepartmentsResponse?.roles?.length &&
                    !getRolesAndDepartmentsResponse?.departments?.length) ||
                  disabled
                ? "#B9ACB4"
                : "#6B5B66",
          }}
        >
          Filtrar
        </Typography>
        <Icons
          name="IconFilter"
          fill="transparent"
          style={{
            color:
              filteredNodeIds.length && activeFilterLength > 0
                ? "#F20D7A"
                : (!getRolesAndDepartmentsResponse?.roles?.length &&
                    !getRolesAndDepartmentsResponse?.departments?.length) ||
                  disabled
                ? "#B9ACB4"
                : "#6B5B66",
          }}
        />
        {activeFilterLength > 0 && (
          <TagNumber
            qtd={activeFilterLength}
            style={{ right: "-4px", top: "-2px" }}
          />
        )}
      </Button>
      <FilterDrawer
        onClose={() => setOpenFilters(false)}
        open={openFilters}
        data={getRolesAndDepartmentsResponse}
        setSelectedFilterOptions={setSelectedFilterOptions}
        getOrgChartResponse={getOrgChartResponse}
      />
    </>
  );
};
