import { forwardRef, useLayoutEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { avatarName } from "src/utils/avatarName";
import { Expand } from "./components/Expand";
import { Card } from "./components/Card";
import { Popover } from "./components/Popover";
import { ModalReplace } from "./components/ModalReplace";
import styled from "styled-components";
import { GetOrgChartByLeaderResponseV2 } from "server/src/services";
import { OrgChart } from "src/lib";
import { CardExport } from "./components/CardExport";
import { ExpandExport } from "./components/ExpandExport";
import { ModalExclude } from "./components/ModalExclude";
import { useNavigate } from "react-router-dom";
import { ModalAddPerson, TModalAddPerson } from "./components/ModalAddPerson";
import { Drawer } from "@components/Drawer";
import { PersonDetails } from "./components/PersonDetails";
import { PersonDetailsSkeleton } from "./components/PersonDetails/PersonDetailsSkeleton";
import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import dispatchToast from "src/utils/dispatchToast";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { segment } from "src/utils/segment";
import { CompanyCardExport } from "./components/CompanyCardExport";

const loadingData = [
  {
    id: "first-node",
    parentId: "",
    name: "Carregando",
  },
  {
    id: "second-node",
    parentId: "first-node",
    name: "Carregando",
  },
  {
    id: "third-node",
    parentId: "first-node",
    name: "Carregando",
  },
];

const Container = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`;

interface IPersonDetailsState {
  open: boolean;
  name: string;
  role: string;
  src: string;
  department: string;
  corporateEmail: string;
  employeeId: string;
  isCEO: boolean;
  leader?: { _id: string; name: string };
}

interface IOrgchart {
  getOrgChartByLeader?: GetOrgChartByLeaderResponseV2;
  getDirectSubordinates: (employeeId: string) => void;
  isLoading?: boolean;
  interactive?: boolean;
  refetch: () => void;
  compactOrgchart: boolean;
  hasPermission?: boolean;
}

export const Orgchart = forwardRef(
  (
    {
      getOrgChartByLeader,
      getDirectSubordinates,
      isLoading,
      interactive = true,
      refetch,
      compactOrgchart = false,
      hasPermission = false,
    }: IOrgchart,
    orgchartRef: any
  ) => {
    const { selectedCompany } = useSelectedCompany();

    const currentCompanyId = selectedCompany?.id;
    const ref = useRef(null);
    const navigate = useNavigate();

    const [modalAddPerson, setModalAddPerson] = useState<
      TModalAddPerson["modalAddPerson"]
    >({
      open: false,
      id: "",
      type: "",
      keepChildrens: true,
      info: {} as any,
    });
    const [modalReplacePerson, setModalReplacePerson] = useState<
      TModalAddPerson["modalAddPerson"]
    >({
      open: false,
      id: "",
      type: "",
      keepChildrens: true,
      info: {} as any,
    });
    const [modalRemove, setModalRemove] = useState<any>({
      open: false,
      id: "",
    });

    const [personDetails, setPersonDetails] = useState<IPersonDetailsState>({
      open: false,
      name: "",
      role: "",
      src: "",
      department: "",
      corporateEmail: "",
      employeeId: "",
      isCEO: false,
      leader: { _id: "", name: "" },
    });

    const [personDetailsPrev, setPersonDetailsPrev] = useState<
      IPersonDetailsState[]
    >([]);

    const [popoverAction, setPopoverAction] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<{
      el: Element | ((element: Element) => Element) | null | undefined;
      node: any;
    } | null>(null);

    const handleNodeClick = (node, event) => {
      const eventTargetClassList = event.target.classList;
      switch (true) {
        case eventTargetClassList.contains("card-button-add"):
          setAnchorEl({ el: event.target, node });
          setPopoverAction("add");
          break;

        case eventTargetClassList.contains("card-button-foreign-object"):
          setAnchorEl({ el: event.target, node });
          setPopoverAction("edit");
          break;

        case eventTargetClassList.contains("expand-btn"):
          getDirectSubordinates(node?.data?.id as string);
          break;

        default:
          segment({
            track: `people_strategic_hr_orgchart_personcard_clicked`,
          });
          setAnchorEl(null);
          setPopoverAction("");
          if (node?.data?.cardType != "company")
            setPersonDetails({
              open: true,
              name: node?.data?.name,
              role: node?.data?.roles?.name,
              src: node?.data?.profilePicture,
              department: node?.data?.departments?.name,
              corporateEmail: node?.data?.corporateEmail,
              employeeId: node?.data?.id,
              isCEO: node?.data?.parentId == selectedCompany?.id ? true : false,
              leader: { _id: node?.parent?.id, name: node?.parent?.data?.name },
            });
      }
    };

    const onTriggerAction = (action: string) => {
      switch (action) {
        case "addPerson":
          setModalAddPerson({
            type: "add",
            id: anchorEl?.node?.id,
            info: {
              ...anchorEl?.node?.data,
              levelToSave: anchorEl?.node?._hierarchyHeight,
              parentIdOrPersonReplaced: anchorEl?.node?.id,
            },
            open: true,
            keepChildrens: true,
          });
          break;
        case "addMultiplePeople":
          navigate(
            `/orgChart/addMultiplePeople/${anchorEl?.node?.id}/${anchorEl?.node?.data?.name}`
          );
          break;
        case "replacePerson":
          setModalReplacePerson({
            type: "replace",
            id: anchorEl?.node?.id,
            info: {
              ...anchorEl?.node?.data,
              levelToSave: anchorEl?.node?._hierarchyHeight,
              parentIdOrPersonReplaced: anchorEl?.node?.id,
            },
            open: true,
            keepChildrens: true,
          });
          break;
        case "deletePerson":
          setModalRemove({ open: true, id: anchorEl?.node?.id });
          break;
      }
    };

    const getPersonDetails = trpc.getPersonDetails.useMutation({
      onSuccess: async (data) => {
        personDetailsPrev.push(personDetails);
        setPersonDetails({
          open: true,
          name: data?.name,
          role: data?.role,
          src: data?.src,
          department: data?.department,
          corporateEmail: data?.corporateEmail,
          employeeId: data?.employeeId,
          isCEO: data?.isCEO,
          leader: data?.leader,
        });
      },
      onError: () => {
        dispatchToast({
          type: "warning",
          content: "Não foi possível obter as informações",
        });
      },
    });

    useLayoutEffect(() => {
      if (!ref?.current || !orgchartRef?.current) return;
      (orgchartRef?.current as OrgChart<any>)
        .container(ref?.current)
        .data(getOrgChartByLeader?.orgchart ?? loadingData)
        .imageName("org-chart")
        .initialZoom(0.8)
        .onNodeClick((node, e) => {
          interactive && handleNodeClick(node, e);
        })
        .buttonContent((node) => {
          const orgchartNode = node?.node;
          node.node.data._totalSubordinates = orgchartNode?.data?.totalReports;

          if (interactive) {
            return ReactDOMServer.renderToString(
              <Expand
                node={orgchartNode}
                totalSubordinates={orgchartNode?.data?.totalReports}
                directSubordinates={orgchartNode?.data?._directSubordinates}
                children={orgchartNode?.children}
              />
            );
          } else {
            return ReactDOMServer.renderToStaticMarkup(
              <ExpandExport
                node={orgchartNode}
                totalSubordinates={orgchartNode?.data?._totalSubordinates}
                directSubordinates={orgchartNode?.data?._directSubordinates}
                children={orgchartNode?.children}
              />
            );
          }
        })
        .nodeContent((d) => {
          const name = d?.data?.name;
          const roles = d?.data?.roles?.name;
          const image = d?.data?.profilePicture;
          const registrationNumber = d?.data?.registrationNumber;
          const orgchartNode = d?.node;
          const disableInteractive = d?.data?.cardType == "company";
          const directReports = d?.data?.directReports;
          const totalReports = d?.data?.totalReports;
          const showButton = !(!!d?._children?.length || !!d?.children?.length);

          switch (true) {
            case interactive || isLoading:
              return ReactDOMServer.renderToString(
                <Card
                  name={name?.toLowerCase()}
                  role={roles}
                  image={image}
                  nameInitials={avatarName(name)}
                  onClick={(e) => handleNodeClick(e, orgchartNode)}
                  isLoading={!!isLoading}
                  registrationNumber={registrationNumber}
                  interactive={interactive && !disableInteractive}
                  hasPermission={hasPermission}
                  directReports={directReports}
                  totalReports={totalReports}
                  showButton={showButton}
                />
              );

            case !!disableInteractive && !!registrationNumber:
              return ReactDOMServer.renderToStaticMarkup(
                <CompanyCardExport
                  name={name?.toLowerCase()}
                  image={image}
                  registrationNumber={registrationNumber}
                />
              );

            default:
              return ReactDOMServer.renderToStaticMarkup(
                <CardExport
                  name={name?.toLowerCase()}
                  role={roles}
                  image={image}
                  nameInitials={avatarName(name)}
                />
              );
          }
        })
        .compact(compactOrgchart)
        .render()
        .expandAll();
    }, [isLoading, getOrgChartByLeader, getDirectSubordinates]);

    const handleRefetch = () => {
      refetch();
      setAnchorEl(null);
    };

    const handlePersonAlreadyInOrgchart = (employeeId: string) => {
      return getOrgChartByLeader?.orgchart?.some(
        (employee) => employee.id == employeeId
      );
    };

    const handlePersonHasChildren = (employeeId: string) => {
      return getOrgChartByLeader?.orgchart?.some(
        (employee) => employee.parentId == employeeId
      );
    };

    const centerPerson = () => {
      if (!orgchartRef?.current?.data()) return;
      orgchartRef?.current?.clearHighlighting();

      const data = orgchartRef.current.data();

      const focusedNodeIndex = data.findIndex(
        (node) => node.id === personDetails?.employeeId
      );

      data[focusedNodeIndex] = {
        ...data[focusedNodeIndex],
        _highlighted: true,
        _expanded: true,
      };

      orgchartRef.current
        .data(data)
        .setCentered(personDetails?.employeeId)
        .render();

      setPersonDetails({
        open: false,
        name: "",
        role: "",
        src: "",
        department: "",
        corporateEmail: "",
        employeeId: "",
        isCEO: false,
        leader: { _id: "", name: "" },
      });
      personDetailsPrev.pop();
    };

    return (
      <>
        <Container>
          {anchorEl && hasPermission && (
            <Popover
              open={!!anchorEl?.el}
              anchorEl={anchorEl?.el}
              onClose={() => setAnchorEl(null)}
              option={popoverAction}
              onClick={onTriggerAction}
            />
          )}
          <div ref={ref} />
        </Container>
        <ModalReplace
          handlePersonAlreadyInOrgchart={(employeeId: string) =>
            handlePersonAlreadyInOrgchart(employeeId)
          }
          handlePersonHasChildren={(employeeId: string) =>
            handlePersonHasChildren(employeeId)
          }
          modalReplacePerson={modalReplacePerson}
          setModalReplacePerson={setModalReplacePerson}
          refetch={handleRefetch}
        />
        <ModalExclude
          orgchartRef={orgchartRef}
          modalRemove={modalRemove}
          setModalRemove={setModalRemove}
          refetch={handleRefetch}
        />
        <ModalAddPerson
          orgchartRef={orgchartRef}
          handlePersonAlreadyInOrgchart={(employeeId: string) =>
            handlePersonAlreadyInOrgchart(employeeId)
          }
          handlePersonHasChildren={(employeeId: string) =>
            handlePersonHasChildren(employeeId)
          }
          setModalAddPerson={setModalAddPerson}
          modalAddPerson={modalAddPerson}
          refetch={handleRefetch}
        />
        <Drawer
          open={personDetails.open}
          onClose={() => {
            setPersonDetails({
              open: false,
              name: "",
              role: "",
              src: "",
              department: "",
              corporateEmail: "",
              employeeId: "",
              isCEO: false,
              leader: { _id: "", name: "" },
            });
            personDetailsPrev.pop();
          }}
          title={"Perfil"}
          loading={getPersonDetails.isLoading}
          headerButton={
            personDetailsPrev?.length ? (
              <Tooltip title="Voltar" placement="top">
                <button style={{ cursor: "pointer" }}>
                  <Icons
                    name="IconArrowLeft"
                    size={24}
                    onClick={() => {
                      setPersonDetails(
                        personDetailsPrev[personDetailsPrev.length - 1]
                      );
                      personDetailsPrev.splice(personDetailsPrev.length - 1, 1);
                    }}
                  />
                </button>
              </Tooltip>
            ) : (
              <></>
            )
          }
          children={
            getPersonDetails.isLoading ? (
              <PersonDetailsSkeleton />
            ) : (
              <PersonDetails
                name={personDetails?.name?.toLowerCase()}
                role={personDetails?.role}
                src={personDetails?.src}
                corporateEmail={personDetails?.corporateEmail}
                department={personDetails?.department}
                employeeId={personDetails?.employeeId}
                isCEO={personDetails?.isCEO}
                leader={personDetails?.leader}
                centerPerson={centerPerson}
                getPersonDetails={() =>
                  getPersonDetails.mutate({
                    companyId: currentCompanyId,
                    employeeId: personDetails?.leader?._id as string,
                  })
                }
              />
            )
          }
        />
      </>
    );
  }
);
