import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FilterAccordion } from "./FilterAccordion";
import {
  Container,
  FiltersContainer,
  FooterContainer,
  StyledButton,
  StyledDrawer,
  StyledIconButton,
  TitleContainer,
} from "./styled";
import { segment } from "src/utils/segment";
import { trpc } from "@api/client";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { GetOrgChartByLeaderResponseV2 } from "server/src/services";

export type TFilters = {
  filteredEmployeeIds: string[];
  roles?: string[];
  departments?: string[];
  filterLength?: any;
};
export interface IRef {
  handleFilterRolesAndDepartments: () => void;
}

interface FilterDrawerProps {
  onClose;
  open;
  data;
  setSelectedFilterOptions: (filters: TFilters) => void;
  getOrgChartResponse: GetOrgChartByLeaderResponseV2 | undefined;
}

export const FilterDrawer = React.forwardRef<IRef, FilterDrawerProps>(
  (
    { onClose, open, data, setSelectedFilterOptions, getOrgChartResponse },
    ref
  ) => {
    const { selectedCompany } = useSelectedCompany();

    const currentCompanyId = selectedCompany?.id;
    const [roles, setRoles] = useState<string[]>([]);
    const [departments, setDepartments] = useState<string[]>([]);
    const [results, setResults] = useState<number>(0);
    const [employeeIds, setEmployeeIds] = useState<string[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<any>();

    const onSubmit = useCallback(async (props: TFilters) => {
      setFilteredOptions({
        roles: props.roles,
        departments: props.departments,
      });
      setSelectedFilterOptions(props);
    }, []);

    const countObjects = useCallback(() => {
      const count = getOrgChartResponse?.orgchart?.reduce((acc, node) => {
        const hasRole = roles.includes(node?.roles?.id);
        const hasDepartment = departments.includes(node?.departments?.id);
        const isValid = hasRole || hasDepartment;

        if (isValid) {
          setEmployeeIds((prevEmployeeIds) => {
            if (!prevEmployeeIds.includes(node?.id)) {
              return [...prevEmployeeIds, node?.id];
            }
            return prevEmployeeIds;
          });
          return acc + 1;
        }

        return acc;
      }, 0);

      setResults(count ?? 0);
    }, [roles, departments, getOrgChartResponse?.orgchart]);

    const submitFilters = useCallback(
      ({ cleanFilters = false }: { cleanFilters?: boolean }) => {
        if (cleanFilters) {
          onSubmit({
            filteredEmployeeIds: [],
            roles: [],
            departments: [],
            filterLength: 0,
          });
        } else {
          onSubmit({
            filteredEmployeeIds: employeeIds,
            roles,
            departments,
            filterLength: roles.length + departments.length,
          });
        }
      },
      [employeeIds, roles, departments, onSubmit]
    );

    useEffect(() => {
      countObjects();
    }, [roles, departments, getOrgChartResponse, countObjects]);

    useImperativeHandle(ref, () => ({
      handleFilterRolesAndDepartments: () =>
        submitFilters({ cleanFilters: false }),
    }));

    return (
      <StyledDrawer
        anchor="right"
        onClose={() => {
          setRoles([]);
          setDepartments([]);
          setEmployeeIds([]);
          submitFilters({ cleanFilters: true });
          onClose();
        }}
        open={open}
      >
        <Container>
          <TitleContainer>
            <Typography variant="headline6" weight={700}>
              Filtros
            </Typography>
            <StyledIconButton
              size="small"
              variant="line"
              icon="IconX"
              onClick={() => {
                setRoles([]);
                setDepartments([]);
                setEmployeeIds([]);
                submitFilters({ cleanFilters: true });
                onClose();
              }}
            />
          </TitleContainer>
          <FiltersContainer>
            {data?.roles?.length ? (
              <FilterAccordion
                title={"Cargos"}
                onClick={(values: any) => {
                  setRoles(values);
                }}
                options={data?.roles}
                initiallySelectedOptions={filteredOptions?.roles || []}
              />
            ) : (
              <></>
            )}
            {data?.departments?.length ? (
              <FilterAccordion
                title={"Departamentos"}
                onClick={(values: any) => {
                  setDepartments(values);
                }}
                options={data?.departments}
                initiallySelectedOptions={filteredOptions?.departments || []}
              />
            ) : (
              <></>
            )}
          </FiltersContainer>
          <FooterContainer>
            <div>
              <LinkButton
                variant="primary"
                onClick={() => {
                  setRoles([]);
                  setDepartments([]);
                  setEmployeeIds([]);
                  submitFilters({ cleanFilters: true });
                  segment({
                    track: `people_strategic_hr_orgchart_filter_button_filters_drawer_cancel_button_clicked`,
                  });

                  onClose();
                }}
                sx={{ padding: "0px" }}
              >
                Cancelar
              </LinkButton>
            </div>
            <StyledButton
              variant="primary"
              size="large"
              onClick={() => {
                submitFilters({ cleanFilters: false });
                segment({
                  track: `people_strategic_hr_orgchart_filter_button_filters_drawer_filterresults_button_clicked`,
                });
                onClose();
              }}
            >
              <Typography variant="body3" weight={700}>
                {`Filtrar (${results} ${
                  results === 1 ? "resultado" : "resultados"
                })`}
              </Typography>
            </StyledButton>
          </FooterContainer>
        </Container>
      </StyledDrawer>
    );
  }
);
